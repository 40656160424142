.weekly-navigation-container { 
  max-width: 100%;
  overflow: hidden;
}

.weekly-navigation {
  min-width: 800px;
  top: 0;
  bottom: 0;
  left: -500%;
  right: -500%;
  margin: 0 auto;
}
